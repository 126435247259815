import { Injectable } from '@angular/core';
import { TvDevice } from '@resparke/models/devices.model';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppStateService } from '../app-state/app-state.service';
import { Observable, tap } from 'rxjs';
import { DeviceType, MediaType } from '../../models/global';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }

  getTvs(): Observable<TvDevice[]> {
    const input = {
      userType: 'TV',
    };
    const statement = `
      query getTvs($input: GetTvsInput!) {
        getTvs(input: $input) {
          id
          tvName
          residentId
          mode
          dateConnected
        }
      }
    `;
    return this.apiService
      .graphql<TvDevice[]>({ statement, variables: { input }, type: 'getTvs' })
      .pipe(
        // map(users => users.sort((a, b) => a.userName > b.userName ? 1 : -1)),
        tap(tvUsers => {
          this.appState.set('tvsCollection', tvUsers);
        })
      )
  }

  getDeviceType(isLoggedIn: boolean): Observable<DeviceType> {
    const userAgent = window.navigator.userAgent;
    const statement = `
      query getDeviceType($userAgent: String!) {
        getDeviceType(userAgent: $userAgent) {
          type
          status
        }
      }
    `;
    return this.apiService
      .graphql<DeviceType>({ statement, variables: { userAgent }, iam: !isLoggedIn, type: 'getDeviceType' })
  }


  updateTv(input: { id: string, update?: { tvName: string, residentId?: string, mode: 'GUEST' | 'RESIDENT' }, disconnect?: boolean, }) {
    const statement = `
      mutation updateTv($input: UpdateTvInput!) {
        updateTv(input: $input) {
          id
          payload
        }
      }
    `;
    return this.apiService
      .graphql<boolean>({ statement, variables: { input }, type: 'updateTv' })
      .pipe(
        tap(() => {
          let tvs = this.appState.get<TvDevice[]>('tvsCollection');
          if (input.update) {
            const target = tvs.find(val => val.id === input.id);
            target.tvName = input.update.tvName;
            target.residentId = input.update.residentId;
            target.mode = input.update.mode;
          } else if (input.disconnect) {
            tvs = tvs.filter(tv => tv.id !== input.id);
          }

          // trigger change detection
          this.appState.set('tvsCollection', [...tvs]);
        })
      )
  }

  cast(input: {
    id: string, cast: {
      type: MediaType;
      id: string;
      trackId: string;
      path: string;
    }
  }) {
    const statement = `
    mutation updateTv($input: UpdateTvInput!) {
      updateTv(input: $input) {
        id
        payload
      }
    }
  `;
  return this.apiService
    .graphql<boolean>({ statement, variables: { input }, type: 'updateTv' })
  }

}
